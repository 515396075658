import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
	DeviceListLevel,
	MediaPermissions,
	StreamError,
	ObjectType,
	MediaTypes,
	UserRoles,
	StatAlarmType,
	AiAlertActivityType,
	LedColors,
	HealthcareErrorCode,
	EventSubscriberRoomType,
	ObservationType,
	ConditionType,
} from 'constants/enums.js';
import Grid from 'components/Grid.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import AlertFeed from 'components/AlertFeedMayo.jsx';
import Alert from 'components/Alert.jsx';
import Notification from 'components/Notification.jsx';
import { Prompt } from 'react-router-dom';
import AmbientMonitoringAlertModal from 'calls/components/AmbientMonitoringAlertModal.jsx';
import _ from 'lodash';
import {
	checkForPermission,
	findSectorById,
	findDeviceById,
	getConfigurationValue,
	updateConfigsList,
	getConfigurationVariant,
	getStorage,
	isValidJSON,
} from 'infrastructure/helpers/commonHelpers.js';
import { getDeviceList } from 'api/devices.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ConferenceModal from 'calls/views/ConferenceModal.jsx';
import { outGoingCallSound } from 'components/CallSounds.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getTeams } from 'api/teams.js';
import { forwardAiAlert } from 'api/alerts.js';
import { getLanguagePrefix } from 'infrastructure/helpers/alertCenterHelper.js';
import {
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import useScreenType from 'hooks/useScreenType.js';
import AndroidSocket from 'infrastructure/socket-client/AndroidInterface.js';
import { getHealthSystemSubTree } from 'api/healthSystems.js';
import NSTUnassigned from 'components/NSTUnassigned.jsx';
import { subscribeToAlerts } from 'api/alert-center.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { getPrecautionObservations, getPrecautions } from 'api/monitoring.js';
import { getPrecautions as getPrecautionsEhr } from 'api/whiteboard.js';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const ehrPrecautions = [
	{
		id: '95018937',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
	},
	{
		id: 'PRE2',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#FFD600',
		icon: 'seizure.svg',
	},
	{
		id: 'PRE3',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#fff',
		boxColor: '#500772',
		icon: 'suicide.svg',
	},
	{
		id: 'PRE6',
		abbreviation: 'AS',
		name: 'Aspiration',
		textColor: '#fff',
		boxColor: '#FFD600',
		icon: 'aspiration.svg',
	},
	{
		id: '140133578',
		abbreviation: 'BSP',
		name: 'Behavioral Safety Plan',
		textColor: '#fff',
		boxColor: '#7B2D9F',
		icon: 'behavioral.svg',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
];

const AlertCenter = () => {
	const healthSystems = useSelector(state => state.healthSystems);
	const user = useSelector(state => state.user);
	const [alertFeeds, setAlertFeeds] = useState([]);
	const [isSessionNotificationVisible, setIsSessionNotificationVisible] = useState(false);
	const [error, setError] = useState('');
	const [selectedFeed, setSelectedFeed] = useState(null);
	const [isObserverConferenceModalOpen, setIsObserverConferenceModalOpen] = useState(false);
	const [hasObserverConferenceStarted, setHasObserverConferenceStarted] = useState(null);
	const [isAmbientMonitoringAlertModalOpen, setIsAmbientMonitoringAlertModalOpen] = useState(false);
	const [observerConferenceData, setObserverConferenceData] = useState({
		participants: [],
		conferenceName: '',
		callType: null,
		roomId: null,
	});
	const [channels, setChannels] = useState([]);
	const [statAlarmSrc, setStatAlarmSrc] = useState(`${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-1.mp3`);

	const intl = useIntl();
	const dispatch = useDispatch();
	const micStatus = useRef(null);
	const playingAlert = useRef(new Audio());
	const [showForwardToNurses, setShowForwardToNurses] = useState(false);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const screenType = useScreenType();
	const { current: socket } = useRef(new AndroidSocket());
	const isDarkMode = true;
	const userId = getStorage().getItem('userId');
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [deviceInformation, setDeviceInformation] = useState();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	useEffect(() => {
		const deviceInfo = socket.getDeviceInfo();

		if (deviceInfo && isValidJSON(deviceInfo)) {
			let parsedInfo = JSON.parse(deviceInfo);
			setDeviceInformation(parsedInfo);
		}
	}, []);

	useEffect(() => {
		if (deviceInformation) {
			window.Android.subscribeToHSEventsAck = data => {
				console.log(data);
			};

			const payload = {
				healthSystemId: deviceInformation.healthSystemId,
				rooms: [
					EventSubscriberRoomType.AI_EVENTS,
					EventSubscriberRoomType.USER_STATUS,
					EventSubscriberRoomType.DEVICE_STATUS,
					EventSubscriberRoomType.PATIENT_EVENTS,
					EventSubscriberRoomType.NURSE_STATION_EVENTS,
				],
			};

			socket.emitWithAck(SocketEvents.Client.SUBSCRIBE_TO_HS_EVENTS, 'subscribeToHSEventsAck', JSON.stringify(payload));
			const arr = [];
			alertFeeds.forEach(feed => {
				let roomExists = deviceInformation.rooms.find(helloDeviceId => feed.deviceId == helloDeviceId);

				if (!roomExists) {
					arr.push(feed.deviceId);
				}
			});
			removeFeeds(arr);
			if (deviceInformation.rooms.length > 0) {
				deviceInformation.rooms.forEach(room => onAddDevice(room));
			}
		}
	}, [deviceInformation]);

	if (window.Android) {
		window.Android.roomsUpdated = data => {
			if (isValidJSON(data)) {
				let tempInfo = _.cloneDeep(deviceInformation);
				tempInfo.rooms = JSON.parse(data);
				setDeviceInformation(tempInfo);
			}
		};
	}

	useEffect(() => {
		const toggleIsFullScreen = () => {
			dispatch(healthSystemsActionCreators.toggleFullscreen());
		};

		document.addEventListener('fullscreenchange', toggleIsFullScreen);
		return () => {
			document.removeEventListener('fullscreenchange', toggleIsFullScreen);
		};
	}, [dispatch]);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			healthSystemsActionCreators.setStreamPermissionMessage(null);
		}
	};

	useEffect(() => {
		const statAlarmCdnUrl = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-`;
		const availableAlarms = [
			{ label: `${intl.formatMessage({ id: 'alarm' })} 1`, id: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
			{ label: `${intl.formatMessage({ id: 'alarm' })} 2`, id: StatAlarmType.ALARM_2, url: `${statAlarmCdnUrl}2.mp3` },
		];

		const statAlarmVariant = getConfigurationVariant(companyConfigurations[MonitoringSettings.StatAlarm]);
		if (statAlarmVariant && statAlarmVariant !== statAlarmSrc && availableAlarms.find(item => item.id === statAlarmVariant)) {
			setStatAlarmSrc(availableAlarms.find(item => item.id === statAlarmVariant).url);
		}
	}, [companyConfigurations, intl, statAlarmSrc]);

	useEffect(() => {
		if (alertFeeds.find(item => item.warning?.value) && !alertFeeds.find(item => item.isStatAlarmActive)) {
			const soundUrl = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;

			if (!playingAlert.current?.src || playingAlert.current?.src !== soundUrl) {
				playingAlert.current.src = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;
				playingAlert.current.loop = true;
				// playingAlert.current.load();
				playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
			}
		}
	}, [alertFeeds]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onAiAlertActivity = data => {
				if (!data || data?.aiAlertActivityTypeId !== AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER) {
					return;
				}
				const newVideoFeeds = alertFeeds.map(item => {
					const newItem = { ...item };
					if (newItem.deviceId === data.deviceId && newItem.intervention) {
						newItem.intervention.value = true;
						newItem.intervention.providerFullName = data.causerFullName;
						newItem.intervention.providerProfilePicture = data.causerProfilePicture;
					}
					return newItem;
				});
				setAlertFeeds(newVideoFeeds);
			};
		}
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, 'onAiAlertActivity');
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY);
		};
	}, [socket, alertFeeds]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onStatAlarm = data => {
				if (isValidJSON(data)) {
					let result = JSON.parse(data);
					if (alertFeeds.find(item => item.deviceId === result.deviceId) && result.isSuccessful) {
						const newAlertFeeds = alertFeeds.map(item => {
							const newItem = { ...item };
							if (item.deviceId === result.deviceId) {
								newItem.isStatAlarmActive = result.data;
								if (result.data) {
									newItem.warning = {};
								}
							}
							return newItem;
						});

						if (!playingAlert?.current) {
							playingAlert.current = new Audio();
						}

						if (playingAlert?.current && result.data) {
							playingAlert.current.src = statAlarmSrc;
							playingAlert.current.pause();
							playingAlert.current.currentTime = 0;
							playingAlert.current.loop = true;
							playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
						}

						if (
							playingAlert?.current &&
							!result.data &&
							!newAlertFeeds.find(item => item.isStatAlarmActive) &&
							result.shouldForward
						) {
							playingAlert.current.currentTime = 0;
							playingAlert.current.loop = false;
							playingAlert.current.pause();
						}
						setAlertFeeds(newAlertFeeds);
					}
				}
			};
		}
		socket.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, 'onStatAlarm');
		return () => {
			socket.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE);
		};
	}, [alertFeeds, socket, statAlarmSrc]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onPatientAiSettingsUpdated = data => {
				if (isValidJSON(data)) {
					const { deviceId, patientAiSettings } = JSON.parse(data);
					if (!alertFeeds.find(item => item.deviceId === deviceId)) {
						return;
					}
					setAiSettingsAction({ deviceId, settings: patientAiSettings });
				}
			};
		}

		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, 'onPatientAiSettingsUpdated');
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED);
		};
	}, [alertFeeds, dispatch, socket]);

	useEffect(() => {
		const currentAudio = playingAlert.current;
		return () => {
			if (currentAudio) {
				currentAudio.pause();
				currentAudio.currentTime = 0;
			}
		};
	}, []);

	useEffect(() => {
		const getMicStatus = async () => {
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			micStatus.current.onchange = onDevicePermissionChange;
		};
		getMicStatus();
	}, []);

	useEffect(() => {
		fetchTeams();
	}, []);

	const stopVoiceOver = (type, deviceId) => {
		const newFeeds = alertFeeds.map(item => {
			const newItem = item;
			if (newItem.deviceId === deviceId && type === newItem.warning.type) {
				newItem.warning = {};
				newItem.isStatAlarmActive = false;
			}
			return newItem;
		});
		if (!newFeeds.find(item => item.warning?.type || item.isStatAlarmActive)) {
			if (playingAlert?.current) {
				playingAlert.current.pause();
				playingAlert.current.currentTime = 0;
				playingAlert.current.src = '';
				playingAlert.current = null;
				playingAlert.current = new Audio();
			}
		}
		setAlertFeeds(newFeeds);
	};

	const getMappedFeed = async (deviceId, isFromSession = false) => {
		const treeData = await getHealthSystemSubTree(deviceInformation.healthSystemId, deviceInformation.companyId);
		const sector = findSectorById(treeData, deviceId);
		if (!sector || (!isFromSession && alertFeeds.some(feed => feed.deviceId === deviceId))) {
			return null;
		}
		const { id, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			deviceId,
			events: [],
			warning: {},
			roomName: name,
			roomId: id,
			hospitalId,
			departmentId,
			floorId,
			deviceOwner: {},
			aiPrivacyStatus,
			hospitalName: name,
			lastCondition: { code: '', display: '', risk: '' },
			alertInfo: {},
			healthSystemId: user.userSession.healthSystem.id,
			intervention: {},
			precautions: [],
		};
	};

	const getUpdatedPrecautionData = (isEhrField, arr, condition, observation) =>
		arr.map(item => {
			const precaution =
				condition.conditions.find(p => p.code === item.id) ||
				(isEhrField && observation.observations.find(p => p.code === item.id));

			const fallPrecaution = '95018937';
			const isFallPrecaution =
				precaution?.code === fallPrecaution &&
				precaution?.observationTypeId === ObservationType.BRADEN_SCORE &&
				precaution?.valueString;

			return {
				...item,
				active: isFallPrecaution ? +precaution.valueString > 7 : !!precaution,
			};
		});

	const setDeviceUpdatedInformation = async (feed, helloDeviceId) => {
		let isDefaultOwner = false;
		const devicesResponse = await getDeviceList(deviceInformation.companyId, DeviceListLevel.ROOM, feed.roomId);
		if (devicesResponse.error) {
			setError(devicesResponse.error.message);
		} else {
			isDefaultOwner = devicesResponse[0].isDefaultOwner;
		}
		let params = {
			healthSystemId: feed.healthSystemId,
			deviceId: feed.deviceId,
			patientId: null,
		};
		if (!isDefaultOwner) {
			let deviceOwner = {};
			const deviceOwnerResponse = await getDeviceOwnerPatient(deviceInformation.companyId, helloDeviceId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
			} else {
				deviceOwner = deviceOwnerResponse;
				feed.deviceOwner = deviceOwner;
				params = {
					healthSystemId: feed.healthSystemId,
					userId: userId,
					deviceId: feed.deviceId,
					patientId: feed.deviceOwner?.healthcareUserId,
				};
			}
		}

		feed.isDefaultOwner = isDefaultOwner;
		const subscribeResponse = await subscribeToAlerts(deviceInformation.deviceId);

		if (subscribeResponse.error) {
			if (subscribeResponse.error.response.data.code === HealthcareErrorCode.MAX_AMBIENT_MONITORING_PROVIDERS) {
				setError(intl.formatMessage({ id: 'maxAmbientMonitoringProviders' }));
			} else {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			}
			return;
		}

		const deviceSettings = subscribeResponse.nurseStationSubscribedDevicePatientAiSettings.find(
			item => item.deviceId === feed.deviceId
		);

		if (deviceSettings) {
			const enabledSettings = deviceSettings.patientAiSettings.filter(setting => setting.isEnabled);
			if (enabledSettings.length > 0) {
				const mappedResponse = {
					deviceId: feed.deviceId,
					settings: enabledSettings,
				};

				dispatch(aiSettingsActionCreators.setPatientAiSettings(mappedResponse));
			}
		}

		feed.isDefaultOwner = isDefaultOwner;

		if (!isDefaultOwner && feed.deviceOwner?.healthcareUserId) {
			const adminRoomSettings = await getRoomSettings(deviceInformation.companyId, feed.roomId, SettingsCategory.MONITORING);

			if (adminRoomSettings.error) {
				setError(adminRoomSettings.error.message);
			} else {
				const isEhrField =
					adminRoomSettings.settings.find(item => item.settingTypeId === MonitoringSettings.Precautions)?.value === 'true' &&
					adminRoomSettings.settings.find(item => item.settingTypeId === MonitoringSettings.Precautions)?.variant ===
						'ehr-patient-data-load';

				feed.isPrecautionsEhrField = isEhrField;

				if (isEhrField) {
					const [conditionsRes, observationsRes] = await Promise.all([
						getPrecautionsEhr(feed.deviceOwner?.healthcareUserId),
						getPrecautionObservations(feed.deviceOwner?.healthcareUserId),
					]);
					if (conditionsRes.error || observationsRes.error) {
						setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					} else {
						feed.precautions = getUpdatedPrecautionData(isEhrField, ehrPrecautions, conditionsRes, observationsRes);
					}
				} else {
					const response = await getPrecautions(feed.deviceOwner.healthcareUserId);
					if (response.error) {
						setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					} else {
						feed.precautions = getUpdatedPrecautionData(isEhrField, initialPrecautions, response);
					}
				}
			}
		}

		setAlertFeeds(prevState => {
			const alreadyExists = prevState.some(existingFeed => existingFeed.deviceId === feed.deviceId);
			return alreadyExists ? prevState : [...prevState, feed];
		});
	};

	const onAddDevice = async selection => {
		const helloDeviceId = selection;
		const selectedFeed = alertFeeds.find(item => item.helloDeviceId === helloDeviceId);
		if (selectedFeed) {
			return;
		}
		const feed = await getMappedFeed(helloDeviceId);
		if (feed) {
			setAlertFeeds(prevState => {
				const alreadyExists = prevState.some(existingFeed => existingFeed.deviceId === feed.deviceId);
				return alreadyExists ? prevState : [...prevState, feed];
			});
			setDeviceUpdatedInformation(feed, helloDeviceId);
		}
	};

	useEffect(() => {
		if (window.Android) {
			window.Android.handleConditionAdded = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (!found || !data.deviceId || !data.conditions || data.deviceId !== found.deviceId) {
						return;
					}
					if (!found.isPrecautionsEhrField) {
						let newPrecautions = _.cloneDeep(initialPrecautions);
						if (data.conditions.length === 0) {
							const newAlertFeeds = alertFeeds.map(item => {
								const newItem = { ...item };
								if (item.deviceId === data.deviceId) {
									newItem.precautions = newPrecautions;
								}
								return newItem;
							});
							setAlertFeeds(newAlertFeeds);
							return;
						}
						if (
							data.conditions.length > 0 &&
							data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION).length === 0
						) {
							return;
						}
						newPrecautions = _.cloneDeep(initialPrecautions).map(precaution => {
							const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
							if (foundPrecaution) {
								precaution.active = true;
							}
							return precaution;
						});
						const newAlertFeeds = alertFeeds.map(item => {
							const newItem = { ...item };
							if (item.deviceId === data.deviceId) {
								newItem.precautions = newPrecautions;
							}
							return newItem;
						});
						setAlertFeeds(newAlertFeeds);
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = ehrPrecautions.map(precaution => {
								const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
								const newPrecaution = { ...precaution };
								if (foundPrecaution) {
									newPrecaution.active = true;
									return newPrecaution;
								} else {
									const foundOtherPrecautions = newItem.precautions.find(x => x.id === precaution.id);
									if (foundOtherPrecautions) {
										newPrecaution.active = !!foundOtherPrecautions?.active;
									}
									return newPrecaution;
								}
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}

		if (window.Android) {
			window.Android.handleConditionRemoved = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);

					if (!found || !data.deviceId || !data.conditions || data.deviceId !== found.deviceId || !found?.isPrecautionsEhrField) {
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = newItem.precautions.map(precaution => {
								const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
								if (foundPrecaution && precaution?.active) {
									const newPrecaution = { ...precaution };
									newPrecaution.active = false;
									return newPrecaution;
								}
								return precaution;
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}

		if (window.Android) {
			window.Android.handleObservationsAdded = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (!found || found.deviceId !== data.deviceId || !found.isPrecautionsEhrField) {
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = ehrPrecautions.map(item => {
								const foundPrecaution = data.observations.find(p => p.code === item.id);
								const newPrecaution = { ...item };

								const fallPrecaution = '95018937';
								const isFallPrecaution =
									foundPrecaution?.code === fallPrecaution &&
									foundPrecaution?.observationTypeId === ObservationType.BRADEN_SCORE &&
									foundPrecaution?.valueString;

								if (foundPrecaution) {
									newPrecaution.active = isFallPrecaution ? +foundPrecaution.valueString > 7 : !!foundPrecaution;
								} else {
									const foundPrevPrecautions = newItem.precautions.find(x => x.id === item.id);
									if (found) {
										newPrecaution.active = !!foundPrevPrecautions?.active;
									}
								}
								return newPrecaution;
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}

		if (window.Android) {
			window.Android.handleObservationsRemoved = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (!found || found.deviceId !== data.deviceId || !found.isPrecautionsEhrField) {
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = newItem.precautions.map(precaution => {
								const foundPrecaution = data.observations.find(condition => condition.code === precaution.id);
								if (foundPrecaution && precaution?.active) {
									const newPrecaution = { ...precaution };
									newPrecaution.active = false;
									return newPrecaution;
								}
								return precaution;
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, 'handleObservationsAdded');
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, 'handleObservationsRemoved');
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, 'handleConditionAdded');
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, 'handleConditionRemoved');
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED);
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED);
		};
	}, [socket, alertFeeds, intl, adminConfigurations]);

	const toggleRoomSettings = feed => {
		let selectedObj = { ...selectedFeed };
		const newFeeds = alertFeeds.map(item => {
			const newItem = item;
			if (item.deviceId === feed.deviceId) {
				newItem.isAlertTimelineVisible = !feed.isAlertTimelineVisible;
				selectedObj = newItem;
			} else {
				newItem.isAlertTimelineVisible = false;
			}
			return newItem;
		});

		setAlertFeeds(newFeeds);
		setSelectedFeed(selectedObj);
	};

	const fetchTeams = async () => {
		if (
			![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) ||
			!user.userSession.healthSystem.id
		) {
			return;
		}
		const response = await getTeams(user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			setChannels(channels);
		}
	};

	const getSelectedFloor = deviceId => {
		const newChannels = [...channels];
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return newChannels.find(channel => channel.floorId === device?.floorId);
	};

	const getHierarchyNaming = deviceId => {
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	const sendAutomaticAlert = async feed => {
		const conversationId = getSelectedFloor(feed.deviceId)?.conversationId;
		const { hospital, department, floor, room } = getHierarchyNaming(feed.deviceId);
		const dataToSend = {
			conversationId,
			alertId: feed.warning.alertId,
			hospital,
			department,
			floor,
			room,
		};
		let response = null;
		if (feed.warning.isAiAlert) {
			response = await forwardAiAlert(dataToSend);
		}
		if (response.error) {
			setError(response.error.message);
		}
		onAlertCloseClick(feed);
	};

	useEffect(() => {
		if (isSessionNotificationVisible) {
			setTimeout(() => {
				setIsSessionNotificationVisible(false);
			}, 2000);
		}
	}, [isSessionNotificationVisible]);

	useEffect(() => {
		if (error === intl.formatMessage({ id: 'maxAmbientMonitoringProviders' })) {
			setTimeout(() => {
				setError('');
			}, 3000);
		}
	}, [error, intl]);

	useEffect(() => {
		if (window.Android) {
			if (alertFeeds?.find(item => item.isStatAlarmActive)) {
				window.Android?.toggleAlertLed(LedColors.RED);
				return;
			}
			window.Android?.toggleAlertLed(LedColors.OFF);
		}
	}, [alertFeeds]);

	const onObserverConferenceEnded = () => {
		setIsObserverConferenceModalOpen(false);
		setHasObserverConferenceStarted(false);
		setObserverConferenceData({
			participants: [],
			conferenceName: '',
			callType: null,
			roomId: null,
		});
	};

	const onConferenceStart = async ({ deviceId, roomName: conferenceName, roomId }, callType) => {
		if (isObserverConferenceModalOpen) {
			return;
		}
		if (micStatus.current?.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_BLOCKED.type,
					message: 'allowMicPermissions',
				})
			);
			return;
		}
		await outGoingCallSound();
		setIsObserverConferenceModalOpen(true);
		setObserverConferenceData({
			conferenceName,
			callType,
			participants: [{ objectId: deviceId, objectType: ObjectType.HELLO_DEVICE }],
			roomId,
		});
	};

	const handleManageAlertClick = feed => {
		setSelectedFeed(feed);
		setIsAmbientMonitoringAlertModalOpen(true);
	};

	const onAlertCloseClick = feed => {
		setIsAmbientMonitoringAlertModalOpen(false);

		if (feed.warning.value && feed.warning.type) {
			stopVoiceOver(feed.warning.type, feed.deviceId);
		}
	};

	const handleProviderIntervening = deviceId => {
		if (!alertFeeds.find(item => item.deviceId === deviceId)) {
			return;
		}
		const newAlertFeeds = alertFeeds.map(item => {
			const newItem = { ...item };
			if (item.deviceId === deviceId) {
				newItem.intervention = {};
			}
			return newItem;
		});
		setAlertFeeds(newAlertFeeds);
	};

	useEffect(() => {
		const getForwardToNursesValue = () => {
			setShowForwardToNurses(getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]));
		};
		getForwardToNursesValue();
	}, [adminConfigurations]);

	useEffect(() => {
		if (selectedFeed && isAmbientMonitoringAlertModalOpen) {
			const fetchRoomSettings = async () => {
				const [adminRoomSettings, myRoomSettings] = await Promise.all([
					getRoomSettings(deviceInformation.companyId, selectedFeed?.roomId, SettingsCategory.MONITORING),
					getUserPreferences(UserSettingTypes.Monitoring, selectedFeed.roomId),
				]);
				const response = updateConfigsList(adminRoomSettings, myRoomSettings);
				if (response.error) {
					setError(response.error.message);
				} else {
					setAdminConfigurations(response.configs);
				}
			};
			fetchRoomSettings();
		}
	}, [selectedFeed, isAmbientMonitoringAlertModalOpen]);

	const removeFeeds = async keys => {
		let feeds = _.cloneDeep(alertFeeds);
		let removedFeeds = feeds.filter(feed => keys.includes(feed.deviceId));
		removedFeeds.forEach(removedFeed => {
			stopVoiceOver(removedFeed.warning.type, removedFeed.deviceId);
		});

		setAlertFeeds(feeds.filter(feed => !keys.includes(feed.deviceId)));
	};

	return (
		<MainLayout>
			{!deviceInformation && <NSTUnassigned />}
			{
				<>
					<Grid
						className={classNames(
							'monitoring-grid ambient-monitoring-grid',
							screenType.isSmall ? 'mobile-monitoring-grid' : '',
							healthSystems.isLeftNavigationExpanded ? 'collapsed-second-column-pilot' : ''
						)}
						stretch='100%'>
						<Grid stretch='100%'>
							<main className='main-view alert-center-view'>
								<section>
									{alertFeeds.length === 0 && (
										<>
											<NSTUnassigned />
										</>
									)}
									{alertFeeds.length > 0 && (
										<>
											<Grid width='100%' gridGap='10px' className={classNames('alert-feeds', `feeds-${alertFeeds.length}`)}>
												{alertFeeds.map(feed => (
													<React.Fragment key={feed.deviceId}>
														<AlertFeed
															feed={feed}
															onSettingsClick={toggleRoomSettings}
															intl={intl}
															onConferenceStart={onConferenceStart}
															onManageAlertClick={handleManageAlertClick}
															onProviderIntervening={handleProviderIntervening}
															isDefaultOwner={feed?.isDefaultOwner}
															setDiscontinueMonitoringReasons={() => null}
															deviceInformation={deviceInformation}
														/>
													</React.Fragment>
												))}
											</Grid>
										</>
									)}
								</section>
							</main>
						</Grid>
						{isObserverConferenceModalOpen && (
							<ConferenceModal
								startConferenceData={observerConferenceData}
								incomingConferenceInfo={null}
								onCallStarted={() => hasObserverConferenceStarted(true)}
								onCallEnded={onObserverConferenceEnded}
								isEmergencyCallOpen={false}
								isDarkMode={isDarkMode}
							/>
						)}
						<Prompt when={alertFeeds.length > 0} message={intl.formatMessage({ id: 'switchingToOtherTab' })} />
						{isSessionNotificationVisible && <Notification text={translate('sessionUpdatedSuccessfully')} />}
						<AmbientMonitoringAlertModal
							feed={selectedFeed}
							display={isAmbientMonitoringAlertModalOpen}
							showForwardToNurses={showForwardToNurses}
							onForwardToNurses={feed => sendAutomaticAlert(feed)}
							onCloseClick={() => onAlertCloseClick(selectedFeed)}
							intl={intl}
							darkMode={user.darkMode}
						/>
					</Grid>
					<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
				</>
			}
		</MainLayout>
	);
};

export default AlertCenter;
