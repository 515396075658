import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import {
	getAiSettingsConfigurations,
	getConfigurationValue,
	getConfigurationVariant,
	updateConfigsList,
} from 'infrastructure/helpers/commonHelpers.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { CallTypes, DeviceStatusMessages, ParticipantRemoveReason, ParticipantState, PatientAiSetting } from 'constants/enums.js';
import Rails from 'icons/Monitoring/Rails.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';
import PatientPrivacy from 'icons/Monitoring/PatientPrivacy.jsx';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import HeartRate from 'icons/Monitoring/HeartRate.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import Eye from 'icons/Monitoring/Eye.jsx';
import {
	AiSetting,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
	MonitoringSettings,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getMonitoringSettingOptions, getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import _ from 'lodash';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Tooltip from 'calls/components/Tooltip.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import Button from 'components/Button.jsx';
import { CallWorkflowType } from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import PrecautionsInCareNotifications from './Monitoring/PrecautionsInCareNotifications.jsx';

const AlertFeed = ({
	className,
	feed,
	onCloseClick,
	onManageAlertClick,
	intl,
	onProviderIntervening,
	isFromMonitoring = false,
	onToggleAlert,
	toggleToMonitoring,
	onDragStart,
	onDragOver,
	onDragEnd,
	reAddFeed,
	closeDeviceLockedModal,
	callManager,
	privacyModeError = false,
	closePrivacyModeError,
	setDiscontinueMonitoringReasons,
	deviceInformation,
}) => {
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const [error, setError] = useState('');
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [nameOfNurseAlsoMonitoring, setNameOfNurseAlsoMonitoring] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));

	const prevDeviceStateRef = useRef(null);
	const maxSimultaneousMonitoringFeedsReachedRef = useRef(false);

	const clickCountRef = useRef(0);
	let clickTimeout;
	const handleMultipleClick = () => {
		clickCountRef.current += 1;
		if (clickTimeout) clearTimeout(clickTimeout);
		clickTimeout = setTimeout(() => {
			clickCountRef.current = 0;
		}, 500);
		if (clickCountRef.current >= 10) {
			window.Android?.goToSettings();
			clickCountRef.current = 0;
			clearTimeout(clickTimeout);
		}
	};

	useEffect(() => {
		const callStateChanged = data => {
			if (data.deviceId === feed.deviceId) {
				const activeDeviceMonitoringFeeds = data.activeConferences?.filter(
					feed => feed.callType === CallTypes.MONITORING
				)?.length;
				const prevIsDeviceOnACall = prevDeviceStateRef.current?.activeConferences?.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);
				const isDeviceOnACall = data.activeConferences.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);

				if (feed.showDeviceControlsLockedModal && !isDeviceOnACall) {
					closeDeviceLockedModal();
				}
				const MaxSimultaneousMonitoringFeeds = 2;
				if (
					prevIsDeviceOnACall &&
					!isDeviceOnACall &&
					activeDeviceMonitoringFeeds < MaxSimultaneousMonitoringFeeds &&
					(feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL || feed.status === ParticipantState.BUSY.type)
				) {
					reAddFeed(feed.deviceId);
				}
				prevDeviceStateRef.current = data;
				const monitoringConnections = data.activeConferences?.filter(feed => feed.callType === CallTypes.MONITORING);

				maxSimultaneousMonitoringFeedsReachedRef.current = activeDeviceMonitoringFeeds >= MaxSimultaneousMonitoringFeeds;
				setNameOfNurseAlsoMonitoring(monitoringConnections.length > 0 ? monitoringConnections?.at(0)?.name : '');
			}
		};

		callManager?.on('call-state-changed', callStateChanged);

		return () => {
			callManager?.off('call-state-changed', callStateChanged);
		};
	}, [callManager, feed]);

	useEffect(() => {
		const fetchDiscontinueMonitoringReasons = async () => {
			const response = await getMonitoringSettingOptions(feed?.healthSystemId, MonitoringSettings.DiscontinueMonitoring);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDiscontinueMonitoringReasons(response);
		};
		if (isFromMonitoring) {
			fetchDiscontinueMonitoringReasons();
		}
	}, [feed?.healthSystemId]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [myRoomSettings, adminAiSettings, aiRoomSettings, adminRoomSettings] = await Promise.all([
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(deviceInformation.companyId, feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
				getRoomSettings(deviceInformation.companyId, feed.roomId, SettingsCategory.MONITORING),
			]);
			const responseError = myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error || adminRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const adminResponse = updateConfigsList(adminRoomSettings, myRoomSettings);

			if (aiResponse.error) {
				setError(aiResponse.error);
			} else {
				setAdminAiConfigurations(aiResponse.configs);
			}
			setAdminConfigurations(adminResponse.configs);
		};
		fetchRoomSettings();
	}, [feed.roomId]);

	useEffect(() => {
		if (feed.intervention?.value) {
			setTimeout(() => {
				onProviderIntervening(feed.deviceId);
			}, 20000);
		}
	}, [feed.intervention, feed.deviceId, onProviderIntervening]);

	const getValue = settingTypeId => {
		const found = aiSettingList
			.find(item => item.deviceId === feed.deviceId)
			?.settings.find(
				item =>
					item.settingTypeId === settingTypeId &&
					[CallWorkflowType.MONITORING, CallWorkflowType.ROUNDING].includes(item.workflowType)
			);
		if (!found) {
			return false;
		}
		return found.isEnabled;
	};

	const aiTypesToShow = [
		{
			aiTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
			label: intl.formatMessage({ id: 'getOutOfBed' }),
			img: <OutOfBed color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) &&
				getValue(PatientAiSetting.PATIENT_GETTING_OUT_OF_BED),
		},
		{
			aiTypeId: PatientAiSetting.RAILS,
			label: intl.formatMessage({ id: 'rails' }),
			img: <Rails color={DarkTheme.colors.graySix} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) && getValue(PatientAiSetting.RAILS),
		},
		{
			aiTypeId: PatientAiSetting.FALL_DETECTED,
			label: intl.formatMessage({ id: 'fallDetection' }),
			img: <FallDetection color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]) &&
				getValue(PatientAiSetting.FALL_DETECTED),
		},
		{
			aiTypeId: PatientAiSetting.PATIENT_MOBILITY,
			label: intl.formatMessage({ id: 'patientMobility' }),
			img: <PatientMobility color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PATIENT_MOBILITY]) &&
				getValue(PatientAiSetting.PATIENT_MOBILITY),
		},
		{
			aiTypeId: PatientAiSetting.PRESSURE_INJURY,
			label: intl.formatMessage({ id: 'pressureInjury' }),
			img: <PressureInjury color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PRESSURE_INJURY]) &&
				getValue(PatientAiSetting.PRESSURE_INJURY),
		},
		{
			aiTypeId: PatientAiSetting.SKELETON_PRIVATE_MODE,
			label: intl.formatMessage({ id: 'privacyMode' }),
			img: <PatientPrivacy color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.AI_PRIVACY_MODE]) &&
				getValue(PatientAiSetting.SKELETON_PRIVATE_MODE),
		},
		{
			aiTypeId: PatientAiSetting.HAND_WASHING,
			label: intl.formatMessage({ id: 'handWashing' }),
			img: <HandWashing color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.HAND_WASHING]) && getValue(PatientAiSetting.HAND_WASHING),
		},
		{
			aiTypeId: PatientAiSetting.VITAL_SIGNS_AI,
			label: intl.formatMessage({ id: 'ewsAiVitalSigns' }),
			img: <HeartRate color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.EWS_AI_VITAL_SIGNS]) &&
				getValue(PatientAiSetting.VITAL_SIGNS_AI),
		},

		{
			aiTypeId: PatientAiSetting.IV_BAG,
			label: intl.formatMessage({ id: 'ivBagFluidLevel' }),
			img: <IvBag color={DarkTheme.colors.graySix} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.IV_BAG_FLUID_LEVEL]) && getValue(PatientAiSetting.IV_BAG),
		},
	];

	const getDeviceStatus = () => {
		const getRetryButton = () => {
			return (
				<>
					<br />
					<Button onClick={() => reAddFeed(feed.deviceId)} className='re-add-feed' text={translate('retry')} />
				</>
			);
		};

		const getNursesInMonitoringNames = () => {
			let names = '';

			if (feed.nursesInConference?.length > 1) {
				names = feed.nursesInConference.map(obj => obj.name).join(` ${intl.formatMessage({ id: 'and' })} `);
			}

			return names;
		};

		if (feed.status === ParticipantState.CONNECTED.type && feed.isCameraPrivacyOn) {
			return DeviceStatusMessages.privacyModeOn;
		}
		switch (feed.status) {
			case ParticipantState.BUSY.type:
				if (maxSimultaneousMonitoringFeedsReachedRef.current) {
					return translate('maximumNumberOfMonitoringSessions', { value: getNursesInMonitoringNames() });
				}

				if (feed.onPatientBusyNurse?.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${feed.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return (
					<>
						{DeviceStatusMessages.notAnswering}
						{getRetryButton()}
					</>
				);
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				if (feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL) {
					return <>{DeviceStatusMessages.disconnectedByCall}</>;
				}
				if (feed.reason === ParticipantRemoveReason.FAILED_TO_GET_INFO) {
					return (
						<>
							{DeviceStatusMessages.failedToGetInfo} {getRetryButton()}
						</>
					);
				}
				return (
					<>
						{DeviceStatusMessages.leftCall} {getRetryButton()}
					</>
				);
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			case ParticipantState.INVITE_DENIED.type:
				return DeviceStatusMessages.inviteDenied;
			case ParticipantState.FAILED_TO_GET_INFO.type:
				return (
					<>
						{DeviceStatusMessages.failedToGetInfo}
						<br />
						<Button onClick={() => reAddFeed(feed.deviceId)} className='re-add-feed' text={translate('retry')} />
					</>
				);
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	};

	const filteredPrecautions = feed.precautions?.filter(item => item.active);
	const shownAiTypes = aiTypesToShow.filter(item => item.show);
	const isAmbientConnected = feed.status === ParticipantState.CONNECTED.type;

	return (
		<>
			<div
				className={classNames(
					'alert-feed flex column-direction full-width full-height',
					className || '',
					feed.warning?.isAiAlert || feed.isStatAlarmActive ? 'high-risk-alert' : '',
					feed.isStatAlarmActive ? 'high-risk-stat-alarm-alert' : '',
					isFromMonitoring ? 'monitoring-alert-feed' : ''
				)}
				onClick={isFromMonitoring && isAmbientConnected ? onToggleAlert : null}
				draggable={isFromMonitoring}
				onDragStart={isFromMonitoring ? onDragStart : null}
				onDragOver={isFromMonitoring ? onDragOver : null}
				onDragEnd={isFromMonitoring ? onDragEnd : null}
				style={{
					cursor: isFromMonitoring ? 'move' : 'initial',
				}}>
				<div className='alert-feed-header' onClick={handleMultipleClick}>
					<span className='alert-feed-room-name'>
						{isFromMonitoring && nameOfNurseAlsoMonitoring && (
							<Tooltip
								text={`${intl.formatMessage({ id: 'beingMonitoredBy' })} ${nameOfNurseAlsoMonitoring}`}
								isPortal={true}
								position='right'>
								<div className='monitoring-nurses-badge'>
									<span className='material-icons'>
										<Eye />
									</span>
								</div>
							</Tooltip>
						)}
						{feed.roomName}
					</span>
					<span className='flex flex-align-center'>
						{isFromMonitoring && (
							<>
								{isAmbientConnected && (
									<>
										<span className='right-s font-10 monitor-patient-title'>Video</span>
										<div className='flex flex-align-center'>
											<label className='switch-label auto-width right-s'>
												<div className='toggle-switch toggle-blue'>
													<input type='' checked={false} onClick={toggleToMonitoring} />
													<span className='toggle-body'>
														<span className='on-text'>{translate('on')}</span>
														<span className='off-text'>{translate('off')}</span>
													</span>
												</div>
											</label>
										</div>
									</>
								)}

								<div
									className='alert-feed-icon-wrapper'
									onClick={event => {
										if (event) {
											event.stopPropagation();
										}
										onCloseClick(getConfigurationValue(adminConfigurations[MonitoringSettings.DiscontinueMonitoring]));
									}}>
									<i className='material-icons-outlined alert-feed-icon'>close</i>
								</div>
							</>
						)}
					</span>
				</div>
				<div className='alert-feed-content-wrapper flex column-direction flex-align-center position-relative overflow-hidden'>
					{(!isFromMonitoring || (isFromMonitoring && isAmbientConnected)) && (
						<>
							{!feed.isDefaultOwner && (
								<>
									{feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<>
											<span className='flex flex-justify-center flex-align-center flex-justify-center'>{feed.warning.icon}</span>
											<span>{feed.warning.text}</span>
											<span
												className='manage-alert-button'
												onClick={isFromMonitoring ? toggleToMonitoring : () => onManageAlertClick(feed)}>
												{translate(isFromMonitoring ? 'turnVideoOn' : 'manageAlert')}
											</span>
										</>
									)}
									{feed.isStatAlarmActive && <span>{translate('statAlarmStarted')}</span>}
									{!feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<div className='flex full-height position-relative gap-s'>
											<PrecautionsInCareNotifications
												filteredPrecautions={filteredPrecautions}
												isEhrField={feed.isPrecautionsEhrField}
											/>
											<div
												className={classNames(
													'flex column-direction full-height full-width text-align-center gap-s',
													filteredPrecautions.length > 0 ? 'has-precautions' : '',
													shownAiTypes.length === 0 ? 'flex-justify-center' : '',
													filteredPrecautions.length === 0 ? 'no-padding-left' : ''
												)}>
												{!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]) &&
													aiTypesToShow.map(
														item =>
															item.show && (
																<div className='alert-feed-active-case' key={item.aiTypeId}>
																	{item.img} <span className='left-s'>{item.label}</span>
																</div>
															)
													)}
												{shownAiTypes.length === 0 && (
													<span className='unoccupied-room'>{translate('ambientMonitoringNotActive')}</span>
												)}
											</div>
										</div>
									)}
								</>
							)}
							{feed.isDefaultOwner && <span className='unoccupied-room'>{translate('unoccupied')}</span>}
							{feed.intervention?.value && (
								<div className='flex flex-justify-center flex-align-center gap-m position-absolute full-width nurse-intervening-container'>
									<ProfilePicture
										fullName={feed.intervention?.providerFullName}
										profilePicture={feed.intervention?.providerProfilePicture}
										className='provider-intervening-img-wrapper'
									/>
									<span className='flex column-direction left-align-items'>
										<span>{feed.intervention?.providerFullName}</span>
										<span>{translate('isIntervening')}</span>
									</span>
								</div>
							)}
						</>
					)}
					{isFromMonitoring && !isAmbientConnected && <span className='unoccupied-room'>{getDeviceStatus()}</span>}
					<ToastMessage
						showToast={privacyModeError}
						onClose={closePrivacyModeError}
						className='feed-toast-message position-absolute'>
						<span>{translate('cannotEnablePrivacyMode')}</span>
					</ToastMessage>
				</div>
			</div>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default AlertFeed;
